dmx.Component('pdf-info', {

  attributes: {
    title: {
      type: String,
      default: '',
    },

    author: {
      type: String,
      default: '',
    },

    subject: {
      type: String,
      default: '',
    },

    keywords: {
      type: String,
      default: '',
    },

    creator: {
      type: String,
      default: 'Wappler PDF Creator',
    },

    producer: {
      type: String,
      default: 'Wappler PDF Creator',
    },
  },

  ui: {
    tagName: 'dmx-pdf-info',
    allowedGlobalChildren: {}
  }

});