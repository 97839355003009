dmx.Component('pdf-footer', {

  attributes: {
    columnGap: {
      type: Number,
      default: 10,
    },

    margin: {
      type: [Number, Array],
      default: 10,
      ui: {
        groupTitle: 'Margin',
        arrayVariables: true,
        isDynamic: true,
        variables: [
          {name: 'marginLeft', type: 'text', title: 'Left', dataBindings: true, arrayIndex:0, defaultValue: 10},
          {name: 'marginTop', type: 'text', title: 'Top', dataBindings: true, arrayIndex:1, defaultValue: 10},
          {name: 'marginRight', type: 'text', title: 'Right', dataBindings: true, arrayIndex:2, defaultValue: 10},
          {name: 'marginBottom', type: 'text', title: 'Bottom', dataBindings: true, arrayIndex:3, defaultValue: 10},
        ]
      },
    },
  },

  ui: {
    tagName: 'dmx-pdf-footer',
    template: `<dmx-pdf-footer>
  <dmx-pdf-text text="##page## / ##total##" alignment="center" fontSize="10" color="gray" />
</dmx-pdf-footer>`,
    children: ['dmx-pdf-text', 'dmx-pdf-image'],
    allowed_children: {'dmx-pdf-text':-1, 'dmx-pdf-image':-1},
    allowedGlobalChildren: {}
  },

  render (node) {
    node.style.display = 'none';
    this.$parse();
  },

  _getAbsoluteUrl (url) {
    const a = document.createElement('a');
    a.href = url;
    return a.href;
  },

  _createFooter () {
    const ret = {
      footer: {
        columns: [],
        ...this.props,
      },
      images: {},
    };

    for (const child of this.children) {
      if (child.data.$type === 'pdf-image') {
        const ref = 'img_ref_' + Math.random().toString(36).slice(2,8);
        const url = this._getAbsoluteUrl(child.props.src);
        const props = {};

        if (child.props.fit) {
          props.fit = [child.props.width || 1000, child.props.height || 1000];
        } else {
          if (child.props.width) props.width = child.props.width;
          if (child.props.height) props.height = child.props.height;
        }

        ret.images[ref] = url;
        ret.footer.columns.push({
          image: ref,
          ...props
        });

        continue;
      }

      ret.footer.columns.push({ ...child.props });
    }

    return {
      footer: (currentPage, pageCount) => {
        const footer = structuredClone(ret.footer);

        for (const col of footer.columns) {
          if (col.text) col.text = col.text.replace(/##page##/g, currentPage).replace(/##total##/g, pageCount);
        }

        //console.info('pdf-footer', footer);

        return footer;
      },
      images: ret.images,
    };
  },

});