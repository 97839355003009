dmx.Component('pdf-watermark', {

  attributes: {
    text: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: 'black',
    },

    opacity: {
      type: Number,
      default: .25,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    italics: {
      type: Boolean,
      default: false,
    },

    fontSize: {
      type: Number,
      default: null,
    },

    angle: {
      type: Number,
      default: -45,
    },
  },

  ui: {
    tagName: 'dmx-pdf-watermark',
    allowedGlobalChildren: {}
  }

});