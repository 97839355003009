dmx.Component('pdf-image', {

  attributes: {
    width: {
      type: Number,
      default: null,
    },

    height: {
      type: Number,
      default: null,
    },

    src: {
      type: String,
      default: '',
      ui: {
        type: 'image',
      }
    },

    fit: {
      type: Boolean,
      default: false,
    },

  },

  ui: {
    tagName: 'dmx-pdf-image',
    allowedGlobalChildren: {}
  }

});