dmx.Component('pdf-content', {

  attributes: {
    removeExtraBlanks: {
      type: Boolean,
      default: false,
    },

    showHidden: {
      type: Boolean,
      default: false,
    },

    removeTagClasses: {
      type: Boolean,
      default: false,
    },

    tableAutoSize: {
      type: Boolean,
      default: false,
    },
  },

  ui: {
    inheritFrom: 'bs5-col'
  },

  _getAbsoluteUrl (url) {
    const a = document.createElement('a');
    a.href = url;
    return a.href;
  },

  _prepareContent (pageSize) {
    //console.log('pageSize', pageSize);
    const contentWidth = pageSize.width - pageSize.margin[0] - pageSize.margin[2];
    const template = document.createElement('template');
    template.innerHTML = this.$node.innerHTML;

    template.content.querySelectorAll('[id]').forEach((el) => {
      el.removeAttribute('id');
    });

    template.content.querySelectorAll('[src]').forEach((el) => {
      el.setAttribute('src', this._getAbsoluteUrl(el.src));
    });

    template.content.querySelectorAll('[href]').forEach((el) => {
      el.setAttribute('href', this._getAbsoluteUrl(el.href));
    });

    template.content.querySelectorAll('.d-print-none').forEach((el) => {
      el.remove();
    });

    template.content.querySelectorAll('.table').forEach((el) => {
      const headerRows = el.querySelectorAll('thead tr');
      const firstRow = el.querySelector('tr').children;
      const cols = firstRow.length;
      const pad = el.classList.contains('table-sm') ? 6 : 12;
      const dividers = [];
      
      let widths = Array.from({length:cols});
      if (el.querySelectorAll('th.w-100, td.w-100').length) { 
        for (let i = 0; i < cols; i++) {
          if (firstRow[i].classList.contains('w-100')) {
            widths[i] = '*';
          } else {
            widths[i] = 'auto';
          }
        }
      } else {
        widths = Array.from({length:cols}).map(() => (contentWidth / cols) - pad);
      }

      if (el.querySelector('tbody.table-group-divider')) {
        dividers.push(headerRows.length);
      }

      if (el.querySelector('tfoot.table-group-divider')) {
        const totalRows = el.querySelectorAll('tr').length;
        const footerRows = el.querySelectorAll('tfoot tr').length;
        dividers.push(totalRows - footerRows);
      }
      
      el.dataset.pdfmake = JSON.stringify({
        layout: 'bootstrap',
        widths: widths,
        dividers: dividers,
        headerRows: headerRows.length
      });
    });

    template.content.querySelectorAll('hr').forEach((el) => {
      el.dataset.pdfmake = JSON.stringify({ width: contentWidth });
    });

    return template.innerHTML;
  },

  _createContent (pageSize) {
    let html = this._prepareContent(pageSize);

    return htmlToPdfmake(html, {
      ...this.props,
      imagesByReference: true,
      defaultStyles: {
        th: { bold: true, fillColor: '' },
      },
      customTag: (params) => {
        // support for columns
        if (params.element.classList.contains('row')) {
          params.ret.columnGap = 10;
          params.ret.columns = params.ret.stack || params.ret.text;
          delete params.ret.stack;
          delete params.ret.text;
        }

        if (/^H\d$/.test(params.element.tagName)) {
          params.ret.tocItem = true;
        }

        return params.ret;
      },
    });
  },

});