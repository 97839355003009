dmx.Component('pdf-creator', {

  attributes: {

    // querySelector to the content element when not as child
    content: {
      type: String,
      default: null,
      ui: {
        valuePrefix: '#',
        valuesSelector: '[is="dmx-pdf-content"]',
        valuesSelectorAttribute: 'id',
        values: [
          { title: 'None', value: ''}
        ]
      }
    },

    compress: {
      type: Boolean,
      default: true,
    },

    userPassword: {
      type: String,
      default: null,
      ui: {
        labelWidth: 120
      }
    },

    ownerPassword: {
      type: String,
      default: null,
      ui: {
        labelWidth: 120
      }
    },

    pageSize: {
      type: String,
      default: 'A4',
      enum: ['4A0', '2A0', 'A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10', 'B0', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10', 'C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'RA0', 'RA1', 'RA2', 'RA3', 'RA4', 'SRA0', 'SRA1', 'SRA2', 'SRA3', 'SRA4', 'LETTER', 'LEGAL', 'TABLOID', 'EXECUTIVE', 'FOLIO'],
      ui: {
        labelWidth: 120
      }
    },

    pageOrientation: {
      type: String,
      default: 'portrait',
      enum: ['portrait', 'landscape'],
      ui: {
        labelWidth: 120
      }
    },

    pageMargins: {
      type: [Number, Array],
      default: [40, 40, 40, 40],
      ui: {
        groupTitle: 'Page Margin',
        arrayVariables: true,
        isDynamic: true,
        variables: [
          {name: 'marginLeft', type: 'text', title: 'Left', dataBindings: true, arrayIndex:0, defaultValue: 40},
          {name: 'marginTop', type: 'text', title: 'Top', dataBindings: true, arrayIndex:1, defaultValue: 40},
          {name: 'marginRight', type: 'text', title: 'Right', dataBindings: true, arrayIndex:2, defaultValue: 40},
          {name: 'marginBottom', type: 'text', title: 'Bottom', dataBindings: true, arrayIndex:3, defaultValue: 40},
        ]
      },
    },
  },

  ui: {
    tagName: 'dmx-pdf-creator',
    children: ['dmx-pdf-info', 'dmx-pdf-style', 'pdf-content', 'dmx-pdf-header', 'dmx-pdf-footer', 'dmx-pdf-watermark'],
    allowed_children: {'dmx-pdf-info':1, 'dmx-pdf-style':-1, 'pdf-content':1, 'dmx-pdf-header':1, 'dmx-pdf-footer':1, 'dmx-pdf-watermark':1},
    allowedGlobalChildren: {},
    linkFiles: [
      {src: 'https://cdn.jsdelivr.net/npm/pdfmake@0.2.9/build/pdfmake.min.js', detect: 'https://cdn\\.jsdelivr\\.net/npm/pdfmake(?:@0\\.\\2.\\d+)?/build/pdfmake\\.min\\.js'},
      {src: 'https://cdn.jsdelivr.net/npm/pdfmake@0.2.9/build/vfs_fonts.min.js',  detect: 'https://cdn\\.jsdelivr\\.net/npm/pdfmake(?:@0\\.\\2.\\d+)?/build/vfs_fonts\\.min\\.js'},
      {src: 'https://cdn.jsdelivr.net/npm/html-to-pdfmake@2.5.2/browser.min.js', detect: 'https://cdn\\.jsdelivr\\.net/npm/html-to-pdfmake(?:@2\\.\\5.\\d+)?/browser\\.min\\.js'}
    ]
  },

  methods: {
    download (filename) {
      this._createPdf().download(filename);
    },

    open () {
      this._createPdf().open();
    },

    print () {
      this._createPdf().print();
    },

    getBase64 () {
      return new Promise((resolve, reject) => {
        this._createPdf().getBase64((data) => {
          resolve(data);
        });
      });
    },

    preview (target) {
      const iframe = document.getElementById(target);
      if (iframe) {
        this._createPdf().getDataUrl((dataUrl) => {
          iframe.src = dataUrl;
        });
      }
    },
  },

  init () {
    const styles = getComputedStyle(document.body);

    if (styles.getPropertyValue('--bs-primary')) {
      this._primary = styles.getPropertyValue('--bs-primary');
      this._secondary = styles.getPropertyValue('--bs-secondary');
      this._success = styles.getPropertyValue('--bs-success');
      this._danger = styles.getPropertyValue('--bs-danger');
      this._warning = styles.getPropertyValue('--bs-warning');
      this._info = styles.getPropertyValue('--bs-info');
      this._light = styles.getPropertyValue('--bs-light');
      this._dark = styles.getPropertyValue('--bs-dark');
      this._styles = this._bootstrapStyles();
    } else {
      this._styles = {};
    }

    this._pageSizes = {
      '4A0': [4767.87, 6740.79],
      '2A0': [3370.39, 4767.87],
      A0: [2383.94, 3370.39],
      A1: [1683.78, 2383.94],
      A2: [1190.55, 1683.78],
      A3: [841.89, 1190.55],
      A4: [595.28, 841.89],
      A5: [419.53, 595.28],
      A6: [297.64, 419.53],
      A7: [209.76, 297.64],
      A8: [147.40, 209.76],
      A9: [104.88, 147.40],
      A10: [73.70, 104.88],
      B0: [2834.65, 4008.19],
      B1: [2004.09, 2834.65],
      B2: [1417.32, 2004.09],
      B3: [1000.63, 1417.32],
      B4: [708.66, 1000.63],
      B5: [498.90, 708.66],
      B6: [354.33, 498.90],
      B7: [249.45, 354.33],
      B8: [175.75, 249.45],
      B9: [124.72, 175.75],
      B10: [87.87, 124.72],
      C0: [2599.37, 3676.54],
      C1: [1836.85, 2599.37],
      C2: [1298.27, 1836.85],
      C3: [918.43, 1298.27],
      C4: [649.13, 918.43],
      C5: [459.21, 649.13],
      C6: [323.15, 459.21],
      C7: [229.61, 323.15],
      C8: [161.57, 229.61],
      C9: [113.39, 161.57],
      C10: [79.37, 113.39],
      RA0: [2437.80, 3458.27],
      RA1: [1729.13, 2437.80],
      RA2: [1218.90, 1729.13],
      RA3: [864.57, 1218.90],
      RA4: [609.45, 864.57],
      SRA0: [2551.18, 3628.35],
      SRA1: [1814.17, 2551.18],
      SRA2: [1275.59, 1814.17],
      SRA3: [907.09, 1275.59],
      SRA4: [637.80, 907.09],
      EXECUTIVE: [521.86, 756.00],
      FOLIO: [612.00, 936.00],
      LEGAL: [612.00, 1008.00],
      LETTER: [612.00, 792.00],
      TABLOID: [792.00, 1224.00],
    };
  },

  _pageSize () {
    const l = this.props.pageOrientation == 'landscape';
    const size = this._pageSizes[this.props.pageSize];
    return {
      width: size[l ? 1 : 0],
      height: size[l ? 0 : 1],
      margin: this.props.pageMargins,
    };
  },

  _createPdf () {
    const docDefinition = {
      styles: this._styles,
      images: {},
      ...this.props,
    };

    for (const child of this.children) {
      switch (child.data.$type) {
        case 'pdf-info':
          docDefinition.info = child.props;
          break;
        case 'pdf-style':
          docDefinition.styles[child.props.name] = child.props;
          break;
        case 'pdf-content':
          {
            const ret = child._createContent(this._pageSize());
            docDefinition.content = ret.content;
            Object.assign(docDefinition.images, ret.images);
          }
          break;
        case 'pdf-header':
          {
            const ret = child._createHeader();
            docDefinition.header = ret.header;
            Object.assign(docDefinition.images, ret.images);
          }
          break;
        case 'pdf-footer':
          {
            const ret = child._createFooter();
            docDefinition.footer = ret.footer;
            Object.assign(docDefinition.images, ret.images);
          }
          break;
        case 'pdf-watermark':
          docDefinition.watermark = child.props;
          break;
      }
    }

    if (this.props.content) {
      const content = document.querySelector(this.props.content);
      if (content && content.dmxComponent) {
        const ret = content.dmxComponent._createContent(this._pageSize());
        docDefinition.content = ret.content;
        Object.assign(docDefinition.images, ret.images);
      }
    }

    docDefinition.pageBreakBefore = (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
      return Array.isArray(currentNode.style) && currentNode.style.includes('pdf-pagebreak-before') && previousNodesOnPage.length;
    };

    if (dmx.debug) {
      console.debug('docDefinition', docDefinition);
    }

    /*
    if (Array.isArray(docDefinition.content)) {
      docDefinition.content.unshift({
        toc: {
          title: { text: 'Table of Contents', style: 'html-h1' },
          pageBreak: 'after',
        },
      });
    }
    */

    return pdfMake.createPdf(docDefinition, {
      bootstrap: this._bootstrapLayout(),
    });
  },

  _bootstrapLayout () {
    if (!this._primary) return {};
    return {
      hLineWidth: (i, node) => {
        const s = Array.isArray(node.table.dividers) && node.table.dividers.includes(i) ? 1 : .5;
        if (node.style.includes('table-borderless')) return 0;
        if (node.style.includes('table-bordered')) return s;
        return i == 0 ? 0 : s;
      },
      vLineWidth: (i, node) => node.style.includes('table-bordered') ? .5 : 0,
      hLineColor: (i, node) => Array.isArray(node.table.dividers) && node.table.dividers.includes(i) ? 'black' : '#b6bfc8',
      vLineColor: (i, node) => '#b6bfc8',
      paddingLeft: (i, node) => node.style.includes('table-sm') ? 3 : 6,
      paddingRight: (i, node) => node.style.includes('table-sm') ? 3 : 6,
      paddingTop: (i, node) => node.style.includes('table-sm') ? 3 : 6,
      paddingBottom: (i, node) => node.style.includes('table-sm') ? 3 : 6,
      fillColor: (i, node) => (node.style.includes('table-striped') && i % 2 == 1) ? '#f2f2f2' : null,
    }
  },

  _bootstrapStyles () {
    return {
      'text-primary': { color: this._primary },
      'text-secondary': { color: this._secondary },
      'text-success': { color: this._success },
      'text-danger': { color: this._danger },
      'text-warning': { color: this._warning },
      'text-info': { color: this._info },
      'text-light': { color: this._light },
      'text-dark': { color: this._dark },
      'link-primary': { color: this._primary },
      'link-secondary': { color: this._secondary },
      'link-success': { color: this._success },
      'link-danger': { color: this._danger },
      'link-warning': { color: this._warning },
      'link-info': { color: this._info },
      'link-light': { color: this._light },
      'link-dark': { color: this._dark },
      'bg-primary': { background: this._primary },
      'bg-secondary': { background: this._secondary },
      'bg-success': { background: this._success },
      'bg-danger': { background: this._danger },
      'bg-warning': { background: this._warning },
      'bg-info': { background: this._info },
      'bg-light': { background: this._light },
      'bg-dark': { background: this._dark },
      'table-primary': { fillColor: this._primary, fillOpacity: 0.25 },
      'table-secondary': { fillColor: this._secondary, fillOpacity: 0.25 },
      'table-success': { fillColor: this._success, fillOpacity: 0.25 },
      'table-danger': { fillColor: this._danger, fillOpacity: 0.25 },
      'table-warning': { fillColor: this._warning, fillOpacity: 0.25 },
      'table-info': { fillColor: this._info, fillOpacity: 0.25 },
      'table-light': { fillColor: this._light, fillOpacity: 0.25 },
      'table-dark': { fillColor: this._dark, fillOpacity: 0.25 },
      'fs-1': { fontSize: 24 },
      'fs-2': { fontSize: 22 },
      'fs-3': { fontSize: 20 },
      'fs-4': { fontSize: 18 },
      'fs-5': { fontSize: 16 },
      'fs-6': { fontSize: 14 },
      'h1': { fontSize: 24 },
      'h2': { fontSize: 22 },
      'h3': { fontSize: 20 },
      'h4': { fontSize: 18 },
      'h5': { fontSize: 16 },
      'h6': { fontSize: 14 },
      'text-start': { alignment: 'left' },
      'text-center': { alignment: 'center' },
      'text-end': { alignment: 'right' },
      'fw-bold': { bold: true },
      'fw-bolder': { bold: true },
      'fw-normal': { bold: false },
      'fst-italic': { italics: true },
      'fst-normal': { italics: false },
      'text-decoration-underline': { decoration: 'underline' },
      'text-decoration-line-through': { decoration: 'lineThrough' },
    };
  },

});