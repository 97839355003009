dmx.Component('pdf-text', {

  attributes: {
    width: { // column width
      type: [String, Number],
      default: null,
    },

    text: {
      type: String,
      default: '',
    },

    fontSize: {
      type: Number,
      default: 12,
    },

    lineHeight: {
      type: Number,
      default: 1,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    italics: {
      type: Boolean,
      default: false,
    },

    alignment: {
      type: String,
      default: null,
      enum: ['left', 'center', 'right', 'justify'],
    },

    color: {
      type: String,
      default: null,
    },

    background: {
      type: String,
      default: null,
    },
  },

  ui: {
    tagName: 'dmx-pdf-text',
    allowedGlobalChildren: {}
  }

});