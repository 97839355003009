dmx.Component('pdf-style', {

  attributes: {
    name: {
      type: String,
      default: '',
      ui: {
        title: 'Class Name'
      }
    },

    font: {
      type: String,
      default: null,
    },

    fontSize: {
      type: Number,
      default: null,
    },

    fontFeatures: {
      type: Array,
      default: null,
    },

    lineHeight: {
      type: Number,
      default: 1,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    italics: {
      type: Boolean,
      default: false,
    },

    alignment: {
      type: String,
      default: null,
      enum: ['left', 'center', 'right', 'justify'],
    },

    color: {
      type: String,
      default: null,
    },

    background: {
      type: String,
      default: null,
      ui: {
        type: 'css_color',
        title: 'Bg Color'
      }
    },

    markerColor: {
      type: String,
      default: null,
    },

    decoration: {
      type: String,
      default: null,
      enum: ['underline', 'lineThrough', 'overline'],
    },

    decorationStyle: {
      type: String,
      default: null,
      enum: ['dotted', 'dashed', 'double', 'wavy'],
      ui: {
        title: 'Style'
      }
    },

    decorationColor: {
      type: String,
      default: null,
      ui: {
        title: 'Color'
      }
    },
  },

  ui: {
    tagName: 'dmx-pdf-style',
    allowedGlobalChildren: {}
  }

});